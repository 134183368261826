import * as React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import DialogTitle from '@mui/material/DialogTitle'
import Dialog from '@mui/material/Dialog'
import Button from '@mui/material/Button'
import './Login.css'
import logo from './img/logo128x128.png'

const useStyles = makeStyles((theme) => ({
    message: {
        'height':'120px',
        'top': '50%',
        'left': '50%',
        'padding': '10px',
        'paddingTop': '60px',
        'color':'#02bf34',
      },
}))

const SuccessEmail = (props) => {
    const classes = useStyles()

    return (
        <>
           
            <div className="maindiv">
            <Dialog open="true">
            <DialogTitle style={{backgroundColor: '#d9d9d9'}}>
                <div className="headerdiv">
                    <div><img src={logo} width='50' height='50'/></div>
                    <div><span className="headertext">BigMama Proxy Network</span></div>
                </div>
            </DialogTitle>
            <div className="sendEmailFieldDiv">
                <div className={classes.message}>
                    Email was sent successfully to {props.email}
                </div>
            </div>
            </Dialog>
         </div>
     </>
    )
}

export default SuccessEmail