import * as React from 'react'
import Button from '@mui/material/Button'
import './Login.css'
import TextField from '@mui/material/TextField'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import FormControl from '@mui/material/FormControl'
import OutlinedInput from '@mui/material/OutlinedInput'
import InputLabel from '@mui/material/InputLabel'
import InputAdornment from '@mui/material/InputAdornment'
import FilledInput from '@mui/material/FilledInput'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import Checkbox from '@mui/material/Checkbox'
import validator from 'validator'
import {Snackbar} from '@material-ui/core'
import { Alert } from '@mui/lab'


const RegistrationForm = (props) => {
    const [showPassword, setShowPassword] = React.useState(false)
    const [open, setOpen] = React.useState(false)
    const [passwordHelperText, setPasswordHelperText] = React.useState("")
    const [openEmail, setOpenEmail] = React.useState(false)
    const [emailHelperText, setEmailHelperText] = React.useState("")
    const [isCheckedTerms, setTermsChecked] = React.useState(false)
    
    const handleClickShowPassword = () => setShowPassword((show) => !show)

    const handleMouseDownPassword = (event) => {
        event.preventDefault()
    }

    const handleAgreeCheckbox = (e) => {
        e.preventDefault()
        let isChecked = e.target.checked
        if (isChecked) {
            setTermsChecked(true)
            if (!passwordValidate(props.password) || !emailValidate(props.userEmail)){
                props.handleButtonStatus(true)
            } else {
                props.handleButtonStatus(false)
            }
        } else {
            setTermsChecked(false)
            props.handleButtonStatus(true)
        }

    }

    const passwordValidate = (value) => {
  
        if (!validator.isStrongPassword(value, {
            minLength: 8, minLowercase: 1,
            minUppercase: 1, minNumbers: 1, minSymbols: 1
        })) {
            setPasswordHelperText("Password is not strong.")
            return false
        }else {
            setPasswordHelperText("")
            return true
        }
    }

    const emailValidate = (email) => {
        const isValidEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g
       
        if(email && email.match(isValidEmail)){
            setEmailHelperText("")
            return true
        }
        setEmailHelperText("Incorrect email.")
        return false
    }
 
    const handleChangePassword = (value) => {
        if (!passwordValidate(value) ){
            setOpen(true)
            props.handleButtonStatus(true)
            props.setPassword(value)
        }else {
            if(!isCheckedTerms || !emailValidate(props.userEmail)) {
                props.handleButtonStatus(true)
            } else {
                props.handleButtonStatus(false)
            }
           
            props.setPassword(value)
        }
    }

    const handleEmailChange = (e) => {
        if(emailValidate(e.target.value)){
            props.setEmail(e.target.value)
            if (!passwordValidate(props.password) || !isCheckedTerms) {
                props.handleButtonStatus(true)
            } else {
                props.handleButtonStatus(false)
            }
        }else {
            props.handleButtonStatus(true)
            props.setEmail(e.target.value)
            setOpenEmail(true)
        }
    }

    const handlePasswordErrorSnackbarClose = () => {
        setOpen(false)
    }

    const handleEmailErrorSnackbarClose = () => {
        setOpenEmail(false)
    }

    const handleChangeInviteCode = (e) => {
        props.setInviteCode(e.target.value)
    }

    const inviteCode = props.invite

    return (
        <div>
            <div className="loginFieldDiv">
            <TextField
                id="loginfield"
                placeholder="yours@example.com"
                className='loginField'
                size="small"
                error={openEmail}
                helperText={emailHelperText}
                onChange={handleEmailChange}
                value={props.userEmail}
                />
            </div>
            <div className='passwordFieldDiv'>
                <TextField
                    id="passwordfield"
                    error={open}
                    helperText={passwordHelperText}
                    placeholder="your password"
                    className='passwordField'
                    type={showPassword ? 'text' : 'password'}
                    onChange={(e) => handleChangePassword(e.target.value)}
                    size="small"  InputProps={{
                        endAdornment: <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                    >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>,
                    }}
                />
            </div>
            <div className="loginFieldDiv">
            <TextField
                id="loginfield"
                placeholder="invite"
                className='loginField'
                size="small"
                value={inviteCode}
                onChange={handleChangeInviteCode}
                />
            </div>
            <div className="error-div">
						{props.errorMessage && ( 
								  <span className="error-text">{props.errorMessage}</span>
						 )}
			</div>
            <div className='agreeMainDiv'>
                <Checkbox size="small" onChange={e => handleAgreeCheckbox(e)}/>
                    <div className='agreeDiv'>
                        <p>Before you sign up, you must accept our <a href="https://bigmama.network/terms_conditions.html">Terms 
                            and Conditions</a> and <a href="https://bigmama.network/privacy.html">Privacy Policy</a></p>
                    </div>
            </div>
            
            <Snackbar
                open={open}
                onClose={handlePasswordErrorSnackbarClose}
                anchorOrigin={{vertical: 'top', horizontal: 'center' }}
                autoHideDuration={6000}>
                <Alert severity="error">Password should be at least 8 characters, 1 upper case, 1 lower case, 1 number, 1 special chars.</Alert>
            </Snackbar>
            <Snackbar
                open={openEmail}
                onClose={handleEmailErrorSnackbarClose}
                anchorOrigin={{vertical: 'top', horizontal: 'center' }}
                autoHideDuration={6000}>
                <Alert severity="error">Incorrect email format.</Alert>
            </Snackbar>
        </div>
    )
}

export default RegistrationForm