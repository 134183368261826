import {signup} from '../config/config'

const sendRegistraionRequest = async (email, passwd, invite) => {
    try {
        const url = signup.url
        
        const body = {
            client_id:  'KukuZ9sx2e1mJzEuJBHlLaFsDwMp6MJU',
            connection: 'Username-Password-Authentication',
            email: email,
            password: passwd,
            user_metadata: {
                invite_code: invite
            }
        }

		const options = {
    		method: 'POST',
    		mode: 'cors',
    		headers: {
    		    'Accept': 'application/json',
				'Content-Type': 'application/json'
    		  },
			body: JSON.stringify(body)
        }
    	
		const resp = await fetch(url, options).then(response => {
        	return response.json()
	    }).then(responseJson => {
	    	if (responseJson.result == true) {
	    		return responseJson;
	    	} else {
	    		if (responseJson.hasOwnProperty('error')) {
	    			throw responseJson.error
	    		}
	    	}
           
        }).catch(error => {
        	throw error
        })
        // console.log(resp);
        
    	return resp;
    } catch (err) {
    	console.error('ERROR SIGNUP:'+err)
        
        throw err
    }
};

export default sendRegistraionRequest;