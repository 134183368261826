import * as React from 'react'
import DialogTitle from '@mui/material/DialogTitle'
import Dialog from '@mui/material/Dialog'
import Button from '@mui/material/Button'
import './Login.css'
import logo from './img/logo128x128.png'
import TextField from '@mui/material/TextField'
import FormControl from '@mui/material/FormControl'
import OutlinedInput from '@mui/material/OutlinedInput'
import InputLabel from '@mui/material/InputLabel'
import InputAdornment from '@mui/material/InputAdornment'
import FilledInput from '@mui/material/FilledInput'
import {Snackbar} from '@material-ui/core'
import { Alert } from '@mui/lab'
import {sendEmailRequest} from './api/changePassword'
import SuccessEmail from "./SuccessEmail"

const SendEmail = (props) => {
    
    const [openEmailError, setOpenEmailError] = React.useState(false)
    const [openEmailSuccess, setOpenEmailSuccess] = React.useState(false)

    const [userEmail, setEmail] = React.useState('')
    const [errorMessage, setErrorMessage] = React.useState(false)

    const [disableButton, setButtonDisable] = React.useState(false)
    const [isSuccessEmail, setIsSuccessEmail] = React.useState(false)

    const handleChangeEmail = (e) => {
        setEmail(e.target.value)
    }

    const handleEmailErrorSnackbarClose = () => {
        setOpenEmailError(false)
    }

    const handleEmailSuccessSnackbarClose = () => {
        setOpenEmailSuccess(false)
    }

    const handleButtonClick = async () => {
        try {
            setButtonDisable(true)
            const rest = await sendEmailRequest(userEmail)
            setErrorMessage(false)
            setOpenEmailSuccess(true)
            setIsSuccessEmail(true)
        }catch (e){
            setErrorMessage(true)
            setOpenEmailError(true)
            setButtonDisable(false)
        }
    }

    return (
        <>
        {!isSuccessEmail ? (
        <>
        <div className="maindiv">
            <Dialog open="true">
            <DialogTitle style={{backgroundColor: '#d9d9d9'}}>
                <div className="headerdiv">
                <div><img src={logo} width='50' height='50'/></div>
                <div><span className="headertext">BigMama Proxy Network</span></div>
                </div>
            </DialogTitle>
            <div>
            <div>
            <div className="sendEmailFieldDiv">
                <TextField
                    id="outlined-size-small"
                    placeholder="yours@example.com"
                    className='loginField'
                    size="small"
                    onChange={handleChangeEmail} 
                />
            </div>
            
            <div className="error-div-send-email">
                        {errorMessage && ( 
                                <span className="error-text">Error while sending email.</span>
                        )}
            </div>
        </div>
                </div>
                <div className='loginButtonDiv'>
                    <Button className='loginButton' sx={{color:'#ffffff'} } onClick={handleButtonClick} disabled={disableButton}>SUBMIT</Button>
                </div>
        </Dialog>
      <Snackbar
            open={openEmailError}
            onClose={handleEmailErrorSnackbarClose}
            autoHideDuration={6000}>
            <Alert severity="error">Error while sending email.</Alert>
        </Snackbar>
        <Snackbar
            open={openEmailSuccess}
            onClose={handleEmailSuccessSnackbarClose}
            autoHideDuration={6000}>
            <Alert severity="success">Email was send to {userEmail} successfully.</Alert>
        </Snackbar>   
    </div>
    </>
        ):(
            <SuccessEmail email={userEmail}/>
        )}
    </>
    )
}

export default SendEmail