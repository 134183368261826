import React from 'react'
import { BrowserRouter, Routes, Route } from "react-router-dom"
import Login from './Login'
import ChangePassword from './ChangePassword'
import NoPage from './NoPage'
import SendEmail from './SendEmail'
import './App.css'
// import ReactGA from 'react-ga'
// import { useEffect } from 'react'

const App = (props) => {
	// useEffect(() => {
    // 		ReactGA.pageview(window.location.pathname + window.location.search)
  	// }, [window.location])

	return (
		<BrowserRouter>
			<Routes>
				<Route index element={<Login />} />
				<Route path="change" element={<ChangePassword />} />
				<Route path="sendemail" element={<SendEmail />} />
				<Route path="*" element={<NoPage />} />
			</Routes>
		
		</BrowserRouter>
		  
    );
}

export default App;
