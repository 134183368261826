import * as React from 'react'
import DialogTitle from '@mui/material/DialogTitle'
import Dialog from '@mui/material/Dialog'
import './Login.css';
import logo from './img/logo128x128.png'
import Tab from '@mui/material/Tab'
import LoginForm from './LoginForm'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import RegistrationForm from './RegistrationForm'
import Button from '@mui/material/Button'
import sendAuthRequest from './api/auth'
import sendRegistraionRequest from './api/signup'

const Login = (props) => {
    const [value, setValue] = React.useState('1')
    const [buttonStatus, setButtonStatus] = React.useState(false)

    const [userEmail, setEmail] = React.useState('')
    const [password, setPassword] = React.useState('')
    const [userInviteCode, setInviteCode] = React.useState(null)
    const [errorMessage, setError] = React.useState('')

    let params = new URLSearchParams(document.location.search)
    let inviteCode = params.get("invite")
    

    const handleChange = (event, newValue) => {
        if(newValue == 1) {
            inviteCode = null
            setButtonStatus(false)
        } else {
            setInviteCode(inviteCode)
            // console.log(userInviteCode)
            setButtonStatus(true)
        }
        setValue(newValue)
    }

    React.useEffect(()=>{
        if (inviteCode != null) {
            setValue('2')
            setInviteCode(inviteCode)           
        } 
    },[inviteCode])

    let buttonText = ''
    
    React.useEffect(()=>{
        if(inviteCode == null && value == 2) {
            inviteCode = 'DIRECT'
            setInviteCode(inviteCode)
        }
    },[value])

    if(value == 1 ) {
        buttonText = 'Log In'
    } else {
        buttonText = 'Sign up'
    }
    
    const handleButtonStatus = (status) => {
        setButtonStatus(status)
    }

    const handleButtonClick = async () => {
        if (value == 1){
            try {
                const resp = await sendAuthRequest(userEmail, password)
                // console.log(resp)
                const urlRedirect =  process.env.REACT_APP_MARKET_REDIRECT_URL+'?token='+resp.access_token
                // console.log(urlRedirect)
                window.location = urlRedirect
            }catch(e){
                setError(e)
            }
        } else {
            try {
                const respReg = await sendRegistraionRequest(userEmail, password, userInviteCode)
                const respLogin = await sendAuthRequest(userEmail, password)
                const urlRedirect =  process.env.REACT_APP_MARKET_REDIRECT_URL+'?token='+respLogin.access_token
                window.location = urlRedirect
            }catch(e){
                setError(e)
            }
        }
    }

	return (
        <div className="maindiv">
            <Dialog open="true">
                <DialogTitle style={{backgroundColor: '#d9d9d9'}}>
                    <div className="headerdiv">
                    <div><img src={logo} width='50' height='50'/></div>
                    <div><span className="headertext">BigMama Proxy Network</span></div>
                    </div>
                </DialogTitle>
                <div>
                    <TabContext value={value}>
                        <TabList onChange={handleChange} variant="fullWidth">
                            <Tab label="Log in" value="1" style={{textTransform:'none'}}/>
                            <Tab label="Sign up" value="2" style={{textTransform:'none'}}/>
                        </TabList>
                        <TabPanel value="1">
                            <LoginForm setEmail={setEmail} setPassword={setPassword} errorMessage={errorMessage} userEmail={userEmail} password={password}/>
                        </TabPanel>
                        <TabPanel value="2">
                            <RegistrationForm invite={userInviteCode} setInviteCode={setInviteCode} handleButtonStatus={handleButtonStatus} setEmail={setEmail} setPassword={setPassword} errorMessage={errorMessage} userEmail={userEmail} password={password}/>
                        </TabPanel>
                    </TabContext>
                    
                </div>
                <div className='loginButtonDiv'>
                    <Button className='loginButton' sx={{color:'#ffffff'} } disabled={buttonStatus} onClick={handleButtonClick}>{buttonText}</Button>
                </div>
            </Dialog>
        </div>
    )
}

export default Login