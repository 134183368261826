import {checkTicket, sendChangePasswordEmail, changePassword} from '../config/config'

export const sendEmailRequest = async (email) => {
    try {
        const url = sendChangePasswordEmail.url

		const options = {
    		method: 'POST',
    		mode: 'cors',
    		headers: {
    		    'Accept': 'application/json',
				'Content-Type': 'application/json'
    		  },
            body: JSON.stringify({'email': email})
        }
    	
		const resp = await fetch(url, options).then(response => {
        	return response.json()
	    }).then(responseJson => {
	    	if (responseJson.result == true) {
	    		return responseJson;
	    	} else {
	    		if (responseJson.hasOwnProperty('error')) {
	    			throw responseJson.error
	    		}
	    	}
           
        }).catch(error => {
        	throw error
        })
        // console.log(resp);
        
    	return resp;
    } catch (err) {
    	console.error('ERROR SENDING EMAIL:'+err)
        
        throw 'ERROR SENDING EMAIL'
    }
};

export const sendCheckTicketRequest = async (ticket) => {
    try {
        const url = checkTicket.url+'?ticket='+ticket

		const options = {
    		method: 'GET',
    		mode: 'cors',
    		headers: {
    		    'Accept': 'application/json',
				'Content-Type': 'application/json'
    		  },
        }
    	
		const resp = await fetch(url, options).then(response => {
        	return response.json()
	    }).then(responseJson => {
	    	if (responseJson.result == true) {
	    		return responseJson;
	    	} else {
	    		if (responseJson.hasOwnProperty('error')) {
	    			throw responseJson.error
	    		}
	    	}
           
        }).catch(error => {
        	throw error
        })
        // console.log(resp);
        
    	return resp;
    } catch (err) {
    	console.error('ERROR CHANGING PASSWORD:'+err)
        
        throw 'ERROR CHANGING PASSWORD'
    }
};

export const sendChangePasswordRequest = async (ticket, password, repassord) => {
    try {
        const url = changePassword.url
        
        const body = {
            code: ticket,
            password1: password,
            password2: repassord,
        }

		const options = {
    		method: 'POST',
    		mode: 'cors',
    		headers: {
    		    'Accept': 'application/json',
				'Content-Type': 'application/json'
    		  },
            body: JSON.stringify(body)
        }
    	
		const resp = await fetch(url, options).then(response => {
        	return response.json()
	    }).then(responseJson => {
	    	if (responseJson.result == true) {
	    		return responseJson;
	    	} else {
	    		if (responseJson.hasOwnProperty('error')) {
	    			throw responseJson.error
	    		}
	    	}
           
        }).catch(error => {
        	throw error
        })
        // console.log(resp);
        
    	return resp;
    } catch (err) {
    	console.error('ERROR CHANGING PASSWORD:'+err)
        
        throw 'ERROR CHANGING PASSWORD'
    }
};