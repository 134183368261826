import * as React from 'react'
import DialogTitle from '@mui/material/DialogTitle'
import Dialog from '@mui/material/Dialog'
import Button from '@mui/material/Button'
import './Login.css'
import logo from './img/logo128x128.png'
import TextField from '@mui/material/TextField'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import FormControl from '@mui/material/FormControl'
import OutlinedInput from '@mui/material/OutlinedInput'
import InputLabel from '@mui/material/InputLabel'
import InputAdornment from '@mui/material/InputAdornment'
import FilledInput from '@mui/material/FilledInput'
import IconButton from '@mui/material/IconButton'
import {Snackbar} from '@material-ui/core'
import { Alert } from '@mui/lab'
import validator from 'validator'
import {sendCheckTicketRequest, sendChangePasswordRequest} from './api/changePassword'
import sendAuthRequest from './api/auth'

const ChangePassword = (props) => {

    let params = new URLSearchParams(document.location.search)
    let ticket = params.get("ticket")

    const [showContent, setShowContent] = React.useState(true)

    React.useEffect(() => {
        sendCheckTicketRequest(ticket).then((resp)=>{
            setShowContent(true)
        }).catch((e)=>{
            setShowContent(false)
        })
    },[])

    const [errorMessage, setError] = React.useState(false)

    const [showPassword, setShowPassword] = React.useState(false)
    const [showRePassword, setShowRePassword] = React.useState(false)
    const [buttonStatus, setButtonStatus] = React.useState(true)
    const [open, setOpen] = React.useState(false)
    const [openRePassordError, setOpenRePasswordError] = React.useState(false)

    const [openPasswordChangeSusccess, setOpenPasswordChangeSusccess] = React.useState(false)
    
    
    const [newPassword, setNewPassword] = React.useState(null)
    const [newRePassword, setNewRePassword] = React.useState(null)

    const handleClickShowPassword = () => setShowPassword((show) => !show)

    const handleMouseDownPassword = (event) => {
        event.preventDefault()
    }

    const handleClickShowRePassword = () => setShowRePassword((show) => !show)

    const handleMouseDownRePassword = (event) => {
        event.preventDefault()
    }

    const handleButtonClick = async () => {
        try {
            setButtonStatus(true)
            const respReg = await sendChangePasswordRequest(ticket, newPassword, newRePassword)
            // console.log(respReg)
            setOpenPasswordChangeSusccess(true)
            const respLogin = await sendAuthRequest(respReg.email, newPassword)
            const urlRedirect =  process.env.REACT_APP_MARKET_REDIRECT_URL+'?token='+respLogin.access_token
            window.location = urlRedirect
        }catch(e){
            setButtonStatus(false)
            setError(true)
        }
    }

    const handlePasswordErrorSnackbarClose = () => {
        setOpen(false)
    }

    const handleRePasswordErrorSnackbarClose = () => {
        setOpenRePasswordError(false)
    }

    const handlePasswordChangeSnackbarClose = () => {
        setOpenPasswordChangeSusccess(false)
    }

    const validate = (value) => {
  
        if (!validator.isStrongPassword(value, {
            minLength: 8, minLowercase: 1,
            minUppercase: 1, minNumbers: 1, minSymbols: 1
        })) {
            setOpen(true)
        } else {
            setNewPassword(value)
        }
    }

    const validateRePassword = (value) => {
  
        if (value !== newPassword) {
            setOpenRePasswordError(true)
        } else {
            setNewRePassword(value)
            setButtonStatus(false)
        }
    }



    return (
        <div className="maindiv">
            {showContent ? (
                <Dialog open="true">
                <DialogTitle style={{backgroundColor: '#d9d9d9'}}>
                    <div className="headerdiv">
                    <div><img src={logo} width='50' height='50'/></div>
                    <div><span className="headertext">BigMama Proxy Network</span></div>
                    </div>
                </DialogTitle>
                <div>
                <div>
            <div className="changePassFieldDiv">
                <TextField
                        id="outlined-size-small"
                        placeholder="new password"
                        className='passwordField'
                        type={showPassword ? 'text' : 'password'}
                        onChange={(e) => validate(e.target.value)}
                        size="small"  InputProps={{
                            endAdornment: <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                        >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>,
                        }} 
                    />
                </div>
                <div className='changePasswordFieldDiv'>
                    <TextField
                        id="outlined-size-small"
                        placeholder="re-password"
                        className='passwordField'
                        type={showRePassword ? 'text' : 'password'}
                        onChange={(e) => validateRePassword(e.target.value)}
                        size="small"  InputProps={{
                            endAdornment: <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowRePassword}
                            onMouseDown={handleMouseDownRePassword}
                            edge="end"
                        >
                            {showRePassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>,
                        }} 
                    />
                </div>
                
                <div className="error-div-change-passwd">
                            {errorMessage && ( 
                                    <span className="error-text">Error while changing password.</span>
                            )}
                </div>
            </div>
                    </div>
                    <div className='loginButtonDiv'>
                        <Button className='loginButton' sx={{color:'#ffffff'} } disabled={buttonStatus} onClick={handleButtonClick}>SUBMIT</Button>
                    </div>
            </Dialog>
          
            ) : (
                <>INVALID TICKET</>
            )
        } 
          <Snackbar
                open={open}
                onClose={handlePasswordErrorSnackbarClose}
                autoHideDuration={3000}>
                <Alert severity="error">Password should be at least 8 characters, 1 upper case, 1 lower case, 1 number, 1 special chars.</Alert>
            </Snackbar>
            <Snackbar
                open={openRePassordError}
                onClose={handleRePasswordErrorSnackbarClose}
                autoHideDuration={3000}>
                <Alert severity="error">Password and Re Password doesn't equal.</Alert>
            </Snackbar>
            <Snackbar
                open={openPasswordChangeSusccess}
                onClose={handlePasswordChangeSnackbarClose}
                autoHideDuration={3000}>
                <Alert severity="success">Your password was changed successfully.</Alert>
            </Snackbar>
        </div>
        
    )
}

export default ChangePassword