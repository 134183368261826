import * as React from 'react'
import Button from '@mui/material/Button'
import './Login.css'
import TextField from '@mui/material/TextField'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import FormControl from '@mui/material/FormControl'
import OutlinedInput from '@mui/material/OutlinedInput'
import InputLabel from '@mui/material/InputLabel'
import InputAdornment from '@mui/material/InputAdornment'
import FilledInput from '@mui/material/FilledInput'
import IconButton from '@mui/material/IconButton'

const LoginForm = (props) => {
    const [showPassword, setShowPassword] = React.useState(false);
    
    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault()
    }

    const handleEmailChange = (e)  => {
        props.setEmail(e.target.value)
    }
    
    const handlePasswdChange = (e)  => {
        props.setPassword(e.target.value )
    }

    return (
        <div>
            <div className="loginFieldDiv">
            <TextField
                id="outlined-size-small"
                placeholder="yours@example.com"
                className='loginField'
                size="small"
                value={props.userEmail} onChange={handleEmailChange} 
                />
            </div>
            <div className='passwordFieldDiv'>
                <TextField
                    id="outlined-size-small"
                    placeholder="your password"
                    className='passwordField'
                    type={showPassword ? 'text' : 'password'}
                    size="small"  InputProps={{
                        endAdornment: <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                    >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>,
                    }}
                    value={props.password} onChange={handlePasswdChange}
                />
            </div>
            <div className='remberDiv'>
                <span><a href="./sendemail">Don't remember your password?</a></span>
            </div>
            <div className="error-div">
						{props.errorMessage && ( 
								  <span className="error-text">{props.errorMessage}</span>
						 )}
			</div>
        </div>
    )
}

export default LoginForm