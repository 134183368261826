export const auth = {
    url: process.env.REACT_APP_AUTH_API_URL 
}

export const signup = {
	    url: process.env.REACT_APP_REGISTER_API_URL
}

export const checkTicket = {
    url: process.env.REACT_APP_CHECK_TICKET_URL
}

export const changePassword = {
    url: process.env.REACT_APP_CHANGE_PASSWORD_URL
}

export const sendChangePasswordEmail = {
    url: process.env.REACT_APP_REQUEST_CHANGE_PASSWORD_EMAIL_URL
}
