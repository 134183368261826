import {auth} from '../config/config'

const sendAuthRequest = async (email, passwd) => {
    try {
        const url = auth.url
        const body = {
            client_id:  'KukuZ9sx2e1mJzEuJBHlLaFsDwMp6MJU',
            username: email,
            password: passwd,
            audience: 'https://collector',
            grant_type: 'authorization_code',
            redirect_uri: 'https://market.bigmama.network'
        }
		const options = {
    		method: 'POST',
    		mode: 'cors',
    		headers: {
    		    'Accept': 'application/json',
				'Content-Type': 'application/json'
    		  },
			body: JSON.stringify(body)
        }
    	
		const resp = await fetch(url, options).then(response => {
        	return response.json()
	    }).then(responseJson => {
	    	if (responseJson.hasOwnProperty('error')) {
	    		throw responseJson.error
	    	}
            // console.log(responseJson)
            return responseJson
        }).catch(error => {
        	throw error
        })
        // console.log('resp');
        // console.log(resp);
        
    	return resp;
    } catch (err) {
    	console.error('ERROR AUTH:'+err)
        
        throw err
    }
};

export default sendAuthRequest;